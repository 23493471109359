<template>
  <div class="content-right">
    <div class="main-content-right">
      <div class="side-menu">
        <div class="side-menu-partners-label">
          Created in collaboration with:
        </div>
        <div class="side-menu-partners">
          <div class="side-menu-partners-logo level-1">
            <img
              class="partner-logo-level-1"
              src="@/assets/logos/systemx2.png"
            />
          </div>
          <div class="side-menu-partners-logo level-1">
            <img
              class="partner-logo-level-1"
              src="@/assets/logos/renault.png"
            />
          </div>
          <div class="side-menu-partners-logo level-1">
            <img
              class="partner-logo-level-1 psa-logo"
              src="@/assets/logos/psa.png"
            />
          </div>
          <div class="side-menu-partners-logo level-1">
            <img class="partner-logo-level-1" src="@/assets/logos/valeo.png" />
          </div>
          <div class="side-menu-partners-logo level-1">
            <img class="partner-logo-level-1" src="@/assets/logos/sherpa.gif" />
          </div>
          <div class="side-menu-partners-logo level-1">
            <img
              class="partner-logo-level-1"
              src="@/assets/logos/siemens.png"
            />
          </div>
          <div class="side-menu-partners-logo level-1">
            <img
              class="partner-logo-level-1 prostep-logo"
              src="@/assets/logos/prostep.jpg"
            />
          </div>
          <div class="side-menu-partners-logo level-1">
            <img
              class="partner-logo-level-1"
              src="@/assets/logos/SETLevelLogo.png"
            />
          </div>
        </div>

        <div class="side-menu-download light-blue">
          <div class="side-menu-label">
            To download the associated documents please use the buttons below:
          </div>
          <div
            class="side-menu-download-button dark-blue download-arrrow hover1"
            @click="openDownload('specification')"
          >
            Specifications (pdf)
          </div>
          <div
            class="side-menu-download-button dark-blue download-arrrow hover1"
            @click="openDownload('introductive')"
          >
            Introductive document (pdf)
          </div>
        </div>
      </div>
    </div>
    <transition name="modal" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="closeModal()">
                  <span aria-hidden="true">×</span>
                </button>
              </slot>
            </div>
            <div class="modal-body">
              <p><b>You can refer to this document as follow:</b></p>
              <p>
                IRT SystemX (2020). Model Identity Card (MIC): Towards a
                standardization of the specification and description of
                simulation models.
                <a href="https://mic.irt-systemx.fr"
                  >https://mic.irt-systemx.fr</a
                >
              </p>
              <p>
                <b>BibTeX:</b> <br />@techreport{mic_2020, <br />
                <span class="tab"
                  >title = {Model {Identity} {Card} ({MIC}): {Towards} a
                  standardization of the specification and description of
                  simulation models}, </span
                ><br /><span class="tab"
                  >url = {https://mic.irt-systemx.fr}, </span
                ><br /><span class="tab"
                  >institution = {IRT SystemX}, year = {2020} }</span
                >
              </p>
              <button class="modal-ok-button" @click="downloadSpecification">
                <span aria-hidden="true">Download</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContentRight",
  data() {
    return {
      showModal: false,
      toDownload: null,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.toDownload = null;
    },
    openDownload(file) {
      this.toDownload =
        file === "specification"
          ? "MIC_Model_Identity_Card_v2.0.pdf"
          : "MIC_Introductive_Document.pdf";
      if (this.$matomo) {
        this.$matomo.trackEvent(
          "Downloads tentative",
          "Fichier " + this.toDownload,
          "Matomo Event Tracking download tentative"
        );
      }
      this.showModal = true;
    },

    async downloadSpecification() {
      if (this.$matomo) {
        this.$matomo.trackEvent(
          "Downloads",
          "Fichier " + this.toDownload,
          "Matomo Event Tracking download"
        );
      }
      let response = await axios.get("/api/send-pdf", {
        params: { filename: this.toDownload },
        responseType: "blob",
      });
      this.showModal = false;
      if (window.navigator.msSaveOrOpenBlob) {
        //IE & Edge
        //msSaveBlob only available for IE & Edge
        const blob = new Blob([response.data], { type: "application/pdf" });
        window.navigator.msSaveBlob(blob, this.toDownload);
      } else {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.toDownload);
        document.body.appendChild(fileLink);
        this.toDownload = null;
        this.showModal = false;
        fileLink.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  padding-left: 30px;
}
.main-content-right {
  margin-left: 5vw;
  width: 45vw;
  min-width: 200px;
  max-width: 380px;
}

.side-menu {
  margin-top: 70px;
}

.side-menu-download {
  box-sizing: border-box;
  opacity: 1;
  width: 100%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  padding: 10px 20px;
}

.side-menu-download-button {
  opacity: 1;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  margin: 50px auto;
  cursor: pointer;
}

.hover1:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.hover2:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.8);
}

.side-menu-link {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin: 20px auto;
  cursor: pointer;
}

.side-menu-links {
  box-sizing: border-box;
  opacity: 1;
  width: 100%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  padding: 10px 20px;
}

.side-router-link {
  display: inline-block;
  line-height: 2em;
  box-sizing: border-box;
  text-decoration: none;
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
}

.dark-blue {
  background-color: #29678a;
}
.light-blue {
  background-color: #429cd9;
}

.download-arrrow {
  background-image: url("~@/assets/images/Download__80x80_White-01.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 40px 30px;
  opacity: 1;
}

.link-arrrow {
  background-image: url("~@/assets/images/Link__80x80_White-01.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.side-menu-partners {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.side-menu-partners-label {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  margin-top: 20px;
  margin-left: 20px;
}

.level-1 {
  width: 100px;
  height: 65px;
  margin: 0 0;
  padding: 10px;
}

.level-2 {
  width: 60px;
  height: 50px;
  margin-bottom: 30px;
  margin-top: 0;
  padding: 0;
}

.partner-logo-level-2 {
  width: 60px;
  margin-bottom: 30px;
  margin-top: 0;
  padding: 0;
}

.partner-logo-level-1 {
  width: 100px;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 0;
}

.psa-logo {
  width: 80px;
  padding-left: 10px;
}

.prostep-logo {
  width: 80px;
  padding-left: 10px;
}

.margin--bottom {
  margin-bottom: 2em;
  margin-top: -25px;
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50vw;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-ok-button {
  display: inline-block;
  width: 20%;
  height: 2em;
  font-size: 1em;
  text-align: center;
  padding: 0.3em 1.2em;
  margin: 20px 40%;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.2s;
  background-color: #4eb5f1;
}

.modal-ok-button:hover {
  border-color: #ccc;
  cursor: pointer;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>